import React, { useState, useEffect } from "react";
import axios from "axios";
import { motion, AnimatePresence } from "framer-motion";
import {
  FaUserPlus,
  FaChartBar,
  FaEdit,
  FaTrash,
  FaSearch,
  FaFilter,
  FaSort,
  FaChevronLeft,
  FaChevronRight,
  FaFilePdf,
  FaFileExcel,
  FaDownload,
  FaUserCircle,
  FaUniversity,
  FaGraduationCap,
} from "react-icons/fa";
import { Line, Pie, Bar, Doughnut, Radar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  BarElement,
  RadialLinearScale,
} from "chart.js";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  BarElement,
  RadialLinearScale
);

const baseUrl = "https://api-collegpt.vercel.app";

function Users() {
  const [users, setUsers] = useState([]);
  const [analytics, setAnalytics] = useState(null);
  const [detailedAnalytics, setDetailedAnalytics] = useState(null);
  const [showAnalytics, setShowAnalytics] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedUniversity, setSelectedUniversity] = useState("");
  const [userFilter, setUserFilter] = useState("all");
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(10);
  const [showUserModal, setShowUserModal] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [activeTab, setActiveTab] = useState("overview");
  const [showExportOptions, setShowExportOptions] = useState(false);

  useEffect(() => {
    fetchUsers();
    fetchAnalytics();
    fetchDetailedAnalytics();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${baseUrl}/users`);
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const fetchAnalytics = async () => {
    try {
      const response = await axios.get(`${baseUrl}/users/analytics/overview`);
      setAnalytics(response.data);
    } catch (error) {
      console.error("Error fetching analytics:", error);
    }
  };

  const fetchDetailedAnalytics = async () => {
    try {
      const response = await axios.get(`${baseUrl}/users/analytics/detailed`);
      setDetailedAnalytics(response.data);
    } catch (error) {
      console.error("Error fetching detailed analytics:", error);
    }
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
    setUserFilter("all");
  };

  const sortedUsers = React.useMemo(() => {
    let sortableUsers = [...users];
    if (sortConfig.key !== null) {
      sortableUsers.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableUsers;
  }, [users, sortConfig]);

  const filteredUsers = sortedUsers.filter(
    (user) =>
      user.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (selectedUniversity === "" || user.university === selectedUniversity) &&
      (userFilter === "all" ||
        (userFilter === "verified" && user.isVerified) ||
        (userFilter === "unverified" && !user.isVerified))
  );

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const universities = [...new Set(users.map((user) => user.university))];

  const getRandomColor = () => {
    return `hsl(${Math.random() * 360}, 70%, 50%)`;
  };

  const genderData = {
    labels: analytics?.genderDistribution.map((item) => item._id) || [],
    datasets: [
      {
        data: analytics?.genderDistribution.map((item) => item.count) || [],
        backgroundColor: [getRandomColor(), getRandomColor(), getRandomColor()],
        hoverBackgroundColor: [
          getRandomColor(),
          getRandomColor(),
          getRandomColor(),
        ],
      },
    ],
  };

  const userGrowthData = {
    labels: ["Week 1", "Week 2", "Week 3", "Week 4"],
    datasets: [
      {
        label: "New Users",
        data: [12, 19, 3, 5],
        borderColor: getRandomColor(),
        backgroundColor: `${getRandomColor()}33`,
        tension: 0.4,
      },
      {
        label: "Active Users",
        data: [15, 22, 8, 10],
        borderColor: getRandomColor(),
        backgroundColor: `${getRandomColor()}33`,
        tension: 0.4,
      },
    ],
  };

  const universityData = {
    labels:
      analytics?.universityDistribution.map(
        (item) => item._id.substring(0, 15) + "..."
      ) || [],
    datasets: [
      {
        label: "Users per University",
        data: analytics?.universityDistribution.map((item) => item.count) || [],
        backgroundColor:
          analytics?.universityDistribution.map(() => getRandomColor()) || [],
      },
    ],
  };

  const skillDistributionData = {
    labels: detailedAnalytics?.skillDistribution.map((item) => item._id) || [],
    datasets: [
      {
        data:
          detailedAnalytics?.skillDistribution.map((item) => item.count) || [],
        backgroundColor:
          detailedAnalytics?.skillDistribution.map(() => getRandomColor()) ||
          [],
      },
    ],
  };

  const semesterDistributionData = {
    labels: [
      "Sem 1",
      "Sem 2",
      "Sem 3",
      "Sem 4",
      "Sem 5",
      "Sem 6",
      "Sem 7",
      "Sem 8",
    ],
    datasets: [
      {
        label: "Students per Semester",
        data: [65, 59, 90, 81, 56, 55, 40, 30],
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const userActivityData = {
    labels: ["Logins", "Posts", "Comments", "Likes", "Shares"],
    datasets: [
      {
        label: "User Activity",
        data: [80, 60, 70, 90, 50],
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
    ],
  };

  const handleAddUser = () => {
    setCurrentUser(null);
    setShowUserModal(true);
  };

  const handleEditUser = (user) => {
    setCurrentUser(user);
    setShowUserModal(true);
  };

  const handleDeleteUser = async (userId) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      try {
        await axios.delete(`${baseUrl}/users/${userId}`);
        fetchUsers();
      } catch (error) {
        console.error("Error deleting user:", error);
      }
    }
  };

  const handleSubmitUser = async (e) => {
    e.preventDefault();
    const userData = {
      name: e.target.name.value,
      email: e.target.email.value,
      university: e.target.university.value,
      sem: e.target.sem.value,
      gender: e.target.gender.value,
    };

    try {
      if (currentUser) {
        await axios.put(`${baseUrl}/users/${currentUser._id}`, userData);
      } else {
        await axios.post(`${baseUrl}/users`, userData);
      }
      fetchUsers();
      setShowUserModal(false);
    } catch (error) {
      console.error("Error submitting user:", error);
    }
  };

  const exportToPDF = () => {
    console.log("Exporting to PDF...");
  };

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(users);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Users");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(data, "users.xlsx");
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="p-4 md:p-8 bg-gradient-to-br from-gray-100 to-gray-200 min-h-screen"
    >
      <div className="flex flex-col md:flex-row justify-between items-center mb-8">
        <motion.h2
          className="text-4xl font-bold mb-4 md:mb-0 text-gray-800"
          initial={{ y: -20 }}
          animate={{ y: 0 }}
          transition={{ type: "spring", stiffness: 300 }}
        >
          Users Dashboard
        </motion.h2>
        <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4">
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => setShowAnalytics(!showAnalytics)}
            className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out shadow-lg flex items-center justify-center"
          >
            <FaChartBar className="mr-2" />
            {showAnalytics ? "Hide Analytics" : "Show Analytics"}
          </motion.button>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={handleAddUser}
            className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out shadow-lg flex items-center justify-center"
          >
            <FaUserPlus className="mr-2" />
            Add User
          </motion.button>
          <motion.div className="relative">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setShowExportOptions(!showExportOptions)}
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out shadow-lg flex items-center justify-center"
            >
              <FaDownload className="mr-2" />
              Export
            </motion.button>
            <AnimatePresence>
              {showExportOptions && (
                <motion.div
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10"
                >
                  <button
                    onClick={exportToPDF}
                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    <FaFilePdf className="inline-block mr-2" /> Export as PDF
                  </button>
                  <button
                    onClick={exportToExcel}
                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    <FaFileExcel className="inline-block mr-2" /> Export as
                    Excel
                  </button>
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        </div>
      </div>

      <AnimatePresence>
        {showAnalytics && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.5 }}
            className="mb-8 p-6 bg-white rounded-xl shadow-2xl"
          >
            <h3 className="text-2xl font-bold mb-6 text-gray-800">
              User Analytics
            </h3>
            <div className="flex mb-4 space-x-4">
              <button
                onClick={() => setActiveTab("overview")}
                className={`px-4 py-2 rounded-full ${
                  activeTab === "overview"
                    ? "bg-indigo-600 text-white"
                    : "bg-gray-200 text-gray-700"
                }`}
              >
                Overview
              </button>
              <button
                onClick={() => setActiveTab("detailed")}
                className={`px-4 py-2 rounded-full ${
                  activeTab === "detailed"
                    ? "bg-indigo-600 text-white"
                    : "bg-gray-200 text-gray-700"
                }`}
              >
                Detailed
              </button>
            </div>
            {activeTab === "overview" ? (
              <div>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
                  <motion.div
                    whileHover={{ scale: 1.05 }}
                    className="bg-gradient-to-r from-purple-400 to-pink-500 p-6 rounded-xl shadow-lg cursor-pointer"
                    onClick={() => setUserFilter("all")}
                  >
                    <h4 className="text-lg font-semibold mb-2 text-white">
                      Total Users
                    </h4>
                    <p className="text-3xl font-bold text-white">
                      {analytics?.totalUsers || 0}
                    </p>
                  </motion.div>
                  <motion.div
                    whileHover={{ scale: 1.05 }}
                    className="bg-gradient-to-r from-green-400 to-blue-500 p-6 rounded-xl shadow-lg cursor-pointer"
                    onClick={() => setUserFilter("verified")}
                  >
                    <h4 className="text-lg font-semibold mb-2 text-white">
                      Verified Users
                    </h4>
                    <p className="text-3xl font-bold text-white">
                      {analytics?.verifiedUsers || 0}
                    </p>
                  </motion.div>
                  <motion.div
                    whileHover={{ scale: 1.05 }}
                    className="bg-gradient-to-r from-yellow-400 to-red-500 p-6 rounded-xl shadow-lg cursor-pointer"
                    onClick={() => setUserFilter("unverified")}
                  >
                    <h4 className="text-lg font-semibold mb-2 text-white">
                      Unverified Users
                    </h4>
                    <p className="text-3xl font-bold text-white">
                      {analytics?.unverifiedUsers || 0}
                    </p>
                  </motion.div>
                  <div className="mb-4">
                    <span className="font-semibold">Current Filter: </span>
                    {userFilter === "all" && "All Users"}
                    {userFilter === "verified" && "Verified Users"}
                    {userFilter === "unverified" && "Unverified Users"}
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div className="bg-white p-4 rounded-lg shadow-lg">
                    <h4 className="text-lg font-semibold mb-2 text-gray-800">
                      Gender Distribution
                    </h4>
                    <div className="h-80">
                      <Pie
                        data={genderData}
                        options={{
                          responsive: true,
                          maintainAspectRatio: false,
                          plugins: {
                            legend: {
                              position: "bottom",
                            },
                          },
                        }}
                      />
                    </div>
                  </div>
                  <div className="p-8 shadow-lg">
                    <div
                      className="bg-white p-4 rounded-lg"
                      style={{ height: "300px" }}
                    >
                      <h4 className="text-lg font-semibold mb-2 text-gray-800">
                        User Growth
                      </h4>
                      <Line
                        data={userGrowthData}
                        options={{
                          responsive: true,
                          maintainAspectRatio: false,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div className="p-8 min-h-[400px] shadow-lg">
                  <div
                    className="bg-white p-4 rounded-lg"
                    style={{ height: "320px" }}
                  >
                    <h4 className="text-lg font-semibold mb-2 text-gray-800">
                      University Distribution
                    </h4>
                    <Bar
                      data={universityData}
                      options={{ responsive: true, maintainAspectRatio: false }}
                    />
                  </div>
                  </div>
                  <div className="p-8 min-h-[400px] shadow-lg">
                  <div
                    className="bg-white p-4 rounded-lg"
                    style={{ height: "300px" }}
                  >
                    <h4 className="text-lg font-semibold mb-2 text-gray-800">
                      Skill Distribution
                    </h4>
                    <Doughnut
                      data={skillDistributionData}
                      options={{ responsive: true, maintainAspectRatio: false }}
                    />
                  </div>
                  </div>
                  <div className="p-8 min-h-[400px] shadow-lg">
                  <div
                    className="bg-white p-4 rounded-lg"
                    style={{ height: "300px" }}
                  >
                    <h4 className="text-lg font-semibold mb-2 text-gray-800">
                      Semester Distribution
                    </h4>
                    <Bar
                      data={semesterDistributionData}
                      options={{ responsive: true, maintainAspectRatio: false }}
                    />
                  </div>
                  </div>
                  <div className="p-8 min-h-[400px] shadow-lg">
                  <div
                    className="bg-white p-4 rounded-lg"
                    style={{ height: "300px" }}
                  >
                    <h4 className="text-lg font-semibold mb-2 text-gray-800">
                      User Activity
                    </h4>
                    <Radar
                      data={userActivityData}
                      options={{ responsive: true, maintainAspectRatio: false }}
                    />
                  </div>
                </div>
                </div>
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>

      <div className="mb-4 flex flex-col md:flex-row justify-between items-center">
        <div className="w-full md:w-1/3 mb-4 md:mb-0">
          <div className="relative">
            <input
              type="text"
              placeholder="Search users..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 shadow-sm"
            />
            <FaSearch className="absolute left-3 top-3 text-gray-400" />
          </div>
        </div>
        <div className="relative inline-block">
          <FaFilter className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none" />
          <select
            value={userFilter}
            onChange={(e) => setUserFilter(e.target.value)}
            className="pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 shadow-sm appearance-none"
          >
            <option value="all">
              Total Users ({analytics?.totalUsers || 0})
            </option>
            <option value="verified">
              Verified Users ({analytics?.verifiedUsers || 0})
            </option>
            <option value="unverified">
              Unverified Users ({analytics?.unverifiedUsers || 0})
            </option>
          </select>
        </div>

        <div className="w-full md:w-1/3">
          <div className="relative">
            <select
              value={selectedUniversity}
              onChange={(e) => setSelectedUniversity(e.target.value)}
              className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 shadow-sm appearance-none"
            >
              <option value="">All Universities</option>
              {universities.map((uni) => (
                <option key={uni} value={uni}>
                  {uni}
                </option>
              ))}
            </select>
            <FaFilter className="absolute left-3 top-3 text-gray-400" />
          </div>
        </div>
      </div>

      <div className="overflow-x-auto shadow-xl rounded-lg">
  <div className="inline-block min-w-full align-middle">
    <div className="overflow-hidden border-b border-gray-200 sm:rounded-lg">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            {["Name", "Email", "University", "Semester", "Actions"].map(
              (header) => (
                <th
                  key={header}
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100 whitespace-nowrap"
                  onClick={() => handleSort(header.toLowerCase())}
                >
                  <div className="flex items-center">
                    {header}
                    <FaSort className="ml-1" />
                  </div>
                </th>
              )
            )}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {currentUsers.map((user) => (
            <motion.tr
              key={user._id}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3 }}
              className="hover:bg-gray-50"
            >
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center">
                  <FaUserCircle className="h-8 w-8 text-blue-600 mr-3 flex-shrink-0" />
                  <div className="text-sm font-medium text-gray-900">
                    {user.name}
                  </div>
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900">{user.email}</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center">
                  <FaUniversity className="h-5 w-5 text-gray-400 mr-2 flex-shrink-0" />
                  <span
                    className="inline-block max-w-xs truncate"
                    title={user.university}
                  >
                    {user.university.slice(0,20)}
                  </span>
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center">
                  <FaGraduationCap className="h-5 w-5 text-gray-400 mr-2 flex-shrink-0" />
                  <span>{user.sem}</span>
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium">
                <button
                  onClick={() => handleEditUser(user)}
                  className="text-indigo-600 hover:text-indigo-900 mr-4 transition duration-300 ease-in-out transform hover:scale-110"
                >
                  <FaEdit />
                </button>
                <button
                  onClick={() => handleDeleteUser(user._id)}
                  className="text-red-600 hover:text-red-900 transition duration-300 ease-in-out transform hover:scale-110"
                >
                  <FaTrash />
                </button>
              </td>
            </motion.tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
</div>

      <div className="mt-4 flex justify-between items-center">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{indexOfFirstUser + 1}</span>{" "}
            to{" "}
            <span className="font-medium">
              {Math.min(indexOfLastUser, filteredUsers.length)}
            </span>{" "}
            of <span className="font-medium">{filteredUsers.length}</span>{" "}
            results
          </p>
        </div>
        <nav
          className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
          aria-label="Pagination"
        >
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            className={`relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 ${
              currentPage === 1 ? "cursor-not-allowed opacity-50" : ""
            }`}
          >
            <FaChevronLeft className="h-5 w-5 mr-2" />
            Previous
          </motion.button>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => paginate(currentPage + 1)}
            disabled={
              currentPage === Math.ceil(filteredUsers.length / usersPerPage)
            }
            className={`relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 ml-3 ${
              currentPage === Math.ceil(filteredUsers.length / usersPerPage)
                ? "cursor-not-allowed opacity-50"
                : ""
            }`}
          >
            Next
            <FaChevronRight className="h-5 w-5 ml-2" />
          </motion.button>
        </nav>
      </div>

      <AnimatePresence>
        {showUserModal && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50"
          >
            <motion.div
              initial={{ scale: 0.5, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.5, opacity: 0 }}
              className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white"
            >
              <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">
                {currentUser ? "Edit User" : "Add New User"}
              </h3>
              <form onSubmit={handleSubmitUser}>
                <div className="mb-4">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    defaultValue={currentUser?.name}
                    required
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    defaultValue={currentUser?.email}
                    required
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="university"
                    className="block text-sm font-medium text-gray-700"
                  >
                    University
                  </label>
                  <input
                    type="text"
                    name="university"
                    id="university"
                    defaultValue={currentUser?.university}
                    required
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="sem"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Semester
                  </label>
                  <input
                    type="text"
                    name="sem"
                    id="sem"
                    defaultValue={currentUser?.sem}
                    required
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="gender"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Gender
                  </label>
                  <select
                    name="gender"
                    id="gender"
                    defaultValue={currentUser?.gender}
                    required
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                </div>
                <div className="mt-5 sm:mt-6">
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    type="submit"
                    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                  >
                    {currentUser ? "Update User" : "Add User"}
                  </motion.button>
                </div>
              </form>
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                onClick={() => setShowUserModal(false)}
                className="absolute top-0 right-0 mt-4 mr-4 text-gray-400 hover:text-gray-500"
              >
                <span className="sr-only">Close</span>
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </motion.button>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
}

export default Users;
