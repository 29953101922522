import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Users from './components/Users';
import Forms from './components/Forms';
import Contact from './components/Contact';
import Contributions from './components/Contribution';
import Doubts from './components/Doubts';
import EventForm from './components/EventForm';
import Feedbacks from './components/Feedbacks';
import AdminLogin from './components/Login';
import { useAuth } from './useAuth';
import { Toaster } from 'react-hot-toast';  
import Dashboard from './components/Dashboard';
function App() {
  useAuth();

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<AdminLogin />} />
        <Route
          path="/*"
          element={
            <div className="flex h-screen bg-gray-100">
              <Sidebar />
              <div className="flex-1 overflow-x-hidden overflow-y-auto">
                <Routes>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/users" element={<Users />} />
                  <Route path="/forms" element={<Forms />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/contributor" element={<Contributions />} />
                  <Route path="/doubt" element={<Doubts />} />
                  <Route path="/eventform" element={<EventForm />} />
                  <Route path="/feedback" element={<Feedbacks />} />
                </Routes>
              </div>
            </div>
          }
        />
      </Routes>
      <Toaster
  position="top-center"
  reverseOrder={false}
  gutter={8}
  containerClassName=""
  containerStyle={{ zIndex: '9999999999' }}
  toastOptions={{
    className: '',
    duration: 5000,
    style: {
      background: 'white',
      color: 'black',
      fontSize: '16px',
    },
    success: {
      duration: 3000,
     
    },
  }}
/>
    </Router>
  );
}

export default App;