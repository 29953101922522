// useAuth.js
import { useEffect } from 'react';
export const useAuth = () => {
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      // Redirect to login page if token is not present
      if (window.location.pathname !== '/login') {
        window.location.href = '/login';
      }
    }
  }, []);
};
