import React, { useState, useEffect } from "react";
import axios from "axios";
import { motion, AnimatePresence } from "framer-motion";
import {
  FaPlus,
  FaEdit,
  FaTrash,
  FaSearch,
  FaExternalLinkAlt,
  FaSort,
  FaChartBar,
} from "react-icons/fa";
import { Bar, Pie, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
} from "chart.js";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement
);

const baseUrl = "https://api-collegpt.vercel.app";

function Forms() {
  const [pdfForms, setPdfForms] = useState([]);
  const [currentPdfForm, setCurrentPdfForm] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [formsPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const [filterSem, setFilterSem] = useState("");
  const [filterAuthor, setFilterAuthor] = useState("");
  const [showAnalytics, setShowAnalytics] = useState(false);

  useEffect(() => {
    fetchPdfForms();
  }, []);

  const fetchPdfForms = async () => {
    try {
      const response = await axios.get(`${baseUrl}/pdf-form`);
      setPdfForms(response.data);
    } catch (error) {
      console.error("Error fetching PDF forms:", error);
    }
  };

  const handleAddEdit = (pdfForm = null) => {
    setCurrentPdfForm(pdfForm);
    setShowModal(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this PDF form?")) {
      try {
        await axios.delete(`${baseUrl}/pdf-form/${id}`);
        fetchPdfForms();
      } catch (error) {
        console.error("Error deleting PDF form:", error);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const pdfFormData = Object.fromEntries(formData.entries());

    try {
      if (currentPdfForm) {
        await axios.put(
          `${baseUrl}/pdf-form/${currentPdfForm._id}`,
          pdfFormData
        );
      } else {
        await axios.post(`${baseUrl}/pdf-form`, pdfFormData);
      }
      fetchPdfForms();
      setShowModal(false);
    } catch (error) {
      console.error("Error submitting PDF form:", error);
    }
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const filteredPdfForms = pdfForms
    .filter(
      (form) =>
        (form.sub.toLowerCase().includes(searchTerm.toLowerCase()) ||
          form.code.toLowerCase().includes(searchTerm.toLowerCase())) &&
        (filterSem === "" || form.sem === filterSem) &&
        (filterAuthor === "" || form.author === filterAuthor)
    )
    .sort((a, b) => {
      if (sortConfig.key) {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];
        if (aValue < bValue) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
      }
      return 0;
    });

  const indexOfLastForm = currentPage * formsPerPage;
  const indexOfFirstForm = indexOfLastForm - formsPerPage;
  const currentForms = filteredPdfForms.slice(
    indexOfFirstForm,
    indexOfLastForm
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const semesterDistribution = pdfForms.reduce((acc, form) => {
    acc[form.sem] = (acc[form.sem] || 0) + 1;
    return acc;
  }, {});

  const subjectDistribution = pdfForms.reduce((acc, form) => {
    acc[form.sub] = (acc[form.sub] || 0) + 1;
    return acc;
  }, {});

  const authorDistribution = pdfForms.reduce((acc, form) => {
    acc[form.author] = (acc[form.author] || 0) + 1;
    return acc;
  }, {});

  const semesterChartData = {
    labels: Object.keys(semesterDistribution),
    datasets: [
      {
        data: Object.values(semesterDistribution),
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#FF9F40",
        ],
      },
    ],
  };

  const subjectChartData = {
    labels: Object.keys(subjectDistribution).slice(0, 5),
    datasets: [
      {
        label: "Number of PDFs",
        data: Object.values(subjectDistribution).slice(0, 5),
        backgroundColor: "rgba(54, 162, 235, 0.6)",
      },
    ],
  };

  const authorChartData = {
    labels: Object.keys(authorDistribution).slice(0, 10),
    datasets: [
      {
        label: "Number of PDFs",
        data: Object.values(authorDistribution).slice(0, 10),
        borderColor: "rgba(75, 192, 192, 1)",
        tension: 0.1,
      },
    ],
  };

  const closeModal = () => {
    setShowModal(false);
    setCurrentPdfForm(null);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="p-4 md:p-8 bg-gray-100 min-h-screen"
    >
      <div className="flex flex-col md:flex-row justify-between items-center mb-6">
        <h2 className="text-3xl font-bold text-gray-800 mb-4 md:mb-0">
          PDF Forms
        </h2>
        <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4">
          <button
            onClick={() => setShowAnalytics(!showAnalytics)}
            className="bg-purple-500 hover:bg-purple-600 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out transform hover:scale-105 shadow-lg"
          >
            <FaChartBar className="inline-block mr-2" />
            {showAnalytics ? "Hide Analytics" : "Show Analytics"}
          </button>
          <button
            onClick={() => handleAddEdit()}
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out transform hover:scale-105 shadow-lg"
          >
            <FaPlus className="inline-block mr-2" />
            Add PDF Form
          </button>
        </div>
      </div>

      <AnimatePresence>
        {showAnalytics && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            className="mb-8 p-4 bg-white rounded-xl shadow-lg"
          >
            <h3 className="text-2xl font-bold mb-4">Analytics</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              <div>
                <h4 className="text-lg font-semibold mb-2">
                  Semester Distribution
                </h4>
                <Pie data={semesterChartData} />
              </div>
              <div>
                <h4 className="text-lg font-semibold mb-2">Top 5 Subjects</h4>
                <Bar data={subjectChartData} />
              </div>
              <div>
                <h4 className="text-lg font-semibold mb-2">Top 10 Authors</h4>
                <Line data={authorChartData} />
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <div className="mb-4 flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0 md:space-x-4">
        <div className="w-full md:w-1/4">
          <div className="relative">
            <input
              type="text"
              placeholder="Search by subject or code..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-sm"
            />
            <FaSearch className="absolute left-3 top-3 text-gray-400" />
          </div>
        </div>
        <div className="w-full md:w-1/4">
          <select
            value={filterSem}
            onChange={(e) => setFilterSem(e.target.value)}
            className="w-full pl-4 pr-8 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-sm"
          >
            <option value="">All Semesters</option>
            {Object.keys(semesterDistribution).map((sem) => (
              <option key={sem} value={sem}>
                {sem}
              </option>
            ))}
          </select>
        </div>
        <div className="w-full md:w-1/4">
          <select
            value={filterAuthor}
            onChange={(e) => setFilterAuthor(e.target.value)}
            className="w-full pl-4 pr-8 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-sm"
          >
            <option value="">All Authors</option>
            {Object.keys(authorDistribution).map((author) => (
              <option key={author} value={author}>
                {author}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                {[
                  "Code",
                  "Subject",
                  "Semester",
                  "Unit",
                  "Author",
                  "Actions",
                ].map((header) => (
                  <th
                    key={header}
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleSort(header.toLowerCase())}
                  >
                    <div className="flex items-center">
                      {header}
                      <FaSort className="ml-1" />
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {currentForms.map((form) => (
                <motion.tr
                  key={form._id}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm font-medium text-gray-900 truncate max-w-xs">
                      {form.code}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900 truncate max-w-xs">
                      {form.sub}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">{form.sem}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">{form.unit}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900 truncate max-w-xs">
                      {form.author}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                    <div className="flex items-center space-x-2">
                      <button
                        onClick={() => handleAddEdit(form)}
                        className="text-blue-500 hover:text-blue-700"
                      >
                        <FaEdit />
                      </button>
                      <button
                        onClick={() => handleDelete(form._id)}
                        className="text-red-500 hover:text-red-700"
                      >
                        <FaTrash />
                      </button>
                      <a
                        href={form.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-green-500 hover:text-green-700"
                      >
                        <FaExternalLinkAlt />
                      </a>
                    </div>
                  </td>
                </motion.tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="mt-4 flex justify-between items-center">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{indexOfFirstForm + 1}</span>{" "}
            to{" "}
            <span className="font-medium">
              {Math.min(indexOfLastForm, filteredPdfForms.length)}
            </span>{" "}
            of <span className="font-medium">{filteredPdfForms.length}</span>{" "}
            results
          </p>
        </div>
        <nav
          className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
          aria-label="Pagination"
        >
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            className={`relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 ${
              currentPage === 1 ? "cursor-not-allowed" : ""
            }`}
          >
            Previous
          </button>
          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={indexOfLastForm >= filteredPdfForms.length}
            className={`relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 ${
              indexOfLastForm >= filteredPdfForms.length
                ? "cursor-not-allowed"
                : ""
            }`}
          >
            Next
          </button>
        </nav>
      </div>

      <AnimatePresence>
        {showModal && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50"
          >
            <motion.div
              initial={{ scale: 0.5, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.5, opacity: 0 }}
              className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white"
            >
              <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">
                {currentPdfForm ? "Edit PDF Form" : "Add New PDF Form"}
              </h3>
              <form onSubmit={handleSubmit}>
                {[
                  "code",
                  "sem",
                  "sub",
                  "unit",
                  "link",
                  "author",
                  "description",
                  "extra",
                  "youtube",
                ].map((field) => (
                  <div key={field} className="mb-4">
                    <label
                      htmlFor={field}
                      className="block text-sm font-medium text-gray-700 capitalize"
                    >
                      {field}
                    </label>
                    <input
                      type="text"
                      name={field}
                      id={field}
                      defaultValue={currentPdfForm ? currentPdfForm[field] : ""}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    />
                  </div>
                ))}
                <div className="mt-5 sm:mt-6">
                  <button
                    type="submit"
                    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm"
                  >
                    {currentPdfForm ? "Update" : "Add"}
                  </button>
                </div>
              </form>
              <button
                onClick={closeModal}
                className="absolute top-0 right-0 mt-4 mr-4 text-gray-400 hover:text-gray-500"
              >
                <span className="sr-only">Close</span>
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
}

export default Forms;
