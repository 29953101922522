import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';
import { FaUsers, FaFileAlt, FaQuestion, FaComments, FaCalendarAlt, FaEnvelope, FaHandsHelping } from 'react-icons/fa';
import { BsFilePdf } from 'react-icons/bs';
import { Link } from 'react-router-dom';

const Dashboard = () => {
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await axios.get('https://api-collegpt.vercel.app/admin/getdashboardstats');
        setStats(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch dashboard stats');
        setLoading(false);
      }
    };

    fetchStats();
  }, []);

  const statCards = [
    { title: 'Total Users', value: stats?.totalUsers, icon: FaUsers, color: 'bg-blue-600', link: '/users' },
    { title: 'Contributions', value: stats?.totalContributions, icon: FaFileAlt, color: 'bg-green-600', link: '/contributor' },
    { title: 'Doubts', value: stats?.totalDoubts, icon: FaQuestion, color: 'bg-yellow-600', link: '/doubt' },
    { title: 'PDF Forms', value: stats?.totalPdfForms, icon: BsFilePdf, color: 'bg-red-600', link: '/forms' },
    { title: 'Feedbacks', value: stats?.totalFeedbacks, icon: FaComments, color: 'bg-purple-600', link: '/feedback' },
    { title: 'Event Forms', value: stats?.totalEventForms, icon: FaCalendarAlt, color: 'bg-indigo-600', link: '/eventform' },
    { title: 'Contacts', value: stats?.totalContacts, icon: FaEnvelope, color: 'bg-pink-600', link: '/contact' },
  ];

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return <div className="flex justify-center items-center h-screen text-red-500">{error}</div>;
  }

  return (
    <div className="min-h-screen bg-gray-100 p-8">
      <div className="flex items-center mb-8">
        <FaHandsHelping className="text-4xl text-blue-600 mr-4" />
        <h1 className="text-4xl font-bold text-gray-800">Pranam Admin Jii</h1>
      </div>
      
      <p className="text-xl mb-8 text-gray-700">
        Aap iss dashboard se jo krna chahe kr skte hai. Yaha se saare crud operation krna bht hi aasan or saral hai.
      </p>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mb-8">
        {statCards.map((card, index) => (
          <Link to={card.link} key={card.title}>
            <motion.div
              className={`${card.color} rounded-lg shadow-lg p-6 text-white hover:shadow-xl transition-shadow duration-300`}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <div className="flex items-center justify-between">
                <div>
                  <h2 className="text-xl font-semibold mb-2">{card.title}</h2>
                  <p className="text-3xl font-bold">{card.value}</p>
                </div>
                <card.icon className="text-4xl opacity-80" />
              </div>
            </motion.div>
          </Link>
        ))}
      </div>

     
    </div>
  );
};

export default Dashboard;