import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  MdPerson, 
  MdFormatListBulleted, 
  MdMail, 
  MdGroupAdd, 
  MdHelpOutline, 
  MdEventNote, 
  MdFeedback 
} from 'react-icons/md';
import toast from 'react-hot-toast';
const menuItems = [
  { path: '/users', icon: MdPerson, text: 'Users' },
  { path: '/forms', icon: MdFormatListBulleted, text: 'Forms' },
  { path: '/contact', icon: MdMail, text: 'Contact' },
  { path: '/contributor', icon: MdGroupAdd, text: 'Contributor' },
  { path: '/doubt', icon: MdHelpOutline, text: 'Doubt' },
  { path: '/eventform', icon: MdEventNote, text: 'EventForm' },
  { path: '/feedback', icon: MdFeedback, text: 'Feedback' },
];

function Sidebar() {
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  const sidebarVariants = {
    open: { x: 0 },
    closed: { x: '-100%' },
  };

  const handleLogout = () => {
    // Perform logout actions here (e.g., clear localStorage)
    localStorage.removeItem('token');
    // Redirect to login page
    toast.success('Logged out successfully');
    window.location.href = '/login';
  };

 

  return (
    <>
      {/* Mobile menu button */}
      <button
        className="fixed top-4 left-4 z-20 md:hidden bg-gray-800 text-white p-2 rounded-md"
        onClick={toggleSidebar}
      >
        {isSidebarOpen ? <FaTimes /> : <FaBars />}
      </button>

      {/* Sidebar */}
      <AnimatePresence>
        {(isSidebarOpen || window.innerWidth > 768) && (
          <motion.div
            initial="closed"
            animate="open"
            exit="closed"
            variants={sidebarVariants}
            transition={{ type: 'spring', stiffness: 300, damping: 30 }}
            className="fixed top-0 left-0 h-full w-64 bg-gray-800 text-white p-4 z-10 md:relative md:translate-x-0"
          >
          <Link to="/"><h1 className="text-2xl font-bold mb-8">Admin Dashboard</h1> </Link>  
        
            <nav>
              {menuItems.map((item) => (
                <Link
                  key={item.path}
                  to={item.path}
                  className={`flex items-center p-2 mb-2 rounded ${
                    location.pathname === item.path ? 'bg-gray-700' : 'hover:bg-gray-700'
                  }`}
                  onClick={() => setIsSidebarOpen(false)}
                >
                  <item.icon className="mr-2" />
                  <span>{item.text}</span>
                </Link>
              ))}
            </nav>
            <button
              className="mt-8 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              onClick={handleLogout}
            >
              Logout
            </button>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Overlay for mobile */}
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-0 md:hidden"
          onClick={toggleSidebar}
        ></div>
      )}
    </>
  );
}

export default Sidebar;
